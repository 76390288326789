import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import TestlektionImg from '../../images/utbildningar/testlektion.jpg'
const Testlektion = () => (
  <Layout>
    <Seo title='Testlektion' />
    <PageHeader text='Testlektion' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <div>
            <h2>Vi rekommenderar testlektion</h2>
            <p>
              Vi rekommenderar att du bokar en testlektion innan du bokar en intensivkurs eller väljer körkortspaket.
              Läraren kan då göra en bättre bedömning om din utbildningstid, kostnad eller om du kommer kunna tillgodogöra dig en intensivutbildning.
              Allt diskuteras givetvis utifrån dina förutsättningar.
            </p>
          </div>

          <div>
            <h3>Testlektion i körkortspaketen</h3>
            <p>
              Självklart går testlektionen att lägga in i våra paket,
              oftast diskuteras detta efter avslutat testlektion. Kontakta gärna oss så berättar vi mer.
            </p>
          </div>

        </div>

        {/* <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3'>
          <div className='card border-0'>
            <img className='card-img-top mb-0' src={require('../../images/testlektion.jpg')} alt='testlektion' />
            <div className='card-body text-center rounded-bottom themeColorTransparent'>
              <p className='card-title text-white'>Gör din anmälan här</p>
              <a href='#1' className='btn btn-light'>Anmälan</a>
            </div>
          </div>
        </div> */}

        <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3 text-center'>
          <img src={TestlektionImg} alt='testlektion' className='img-fluid rounded' />
          <p className='mb-1 text-sm-center'>
            <Link to='/kontakta-oss/utbildningsanmalan' className='btn btn-danger'> Gör din anmälan här</Link>
          </p>
        </div>

      </div>
    </div>
  </Layout>
)

export default Testlektion
